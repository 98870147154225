<template>
  <v-row class="fill-height" style="padding: 0; background-color: #FF0000;">
    <v-container class="fill-height">
      <v-layout column align-center justify-center v-if="url">
        <div class="logo">idenfit</div>
        <h1 class="white--text mb-4">{{ $t("message.download_email_report") }}</h1>
        <file-download-button large color="red" v-if="url" :url="url" class="mb-5" />
        <v-btn text large @click="$router.push({ name: 'dashboard' })" dark>
          <span class="font-weight-bold title">{{ $t("buttons.back_to_home") }}</span>
        </v-btn>
      </v-layout>
    </v-container>
  </v-row>
</template>

<script>
  export default {
    name: "download",
    components: {},
    data: () => ({
      url: null
    }),
    methods: {},
    beforeRouteEnter(to, from, next) {
      if (!to.query.url) {
        next({ name: "pageNotFound" });
      } else {
        next();
      }
    },
    created() {
      this.url = this.$route.query.url;
    }
  };
</script>

<style scoped>
  .logo {
    font-family: "Paytone One", sans-serif;
    font-size: 32px;
    font-weight: 400;
    color: #ff0000;
    letter-spacing: 0;
    cursor: pointer;
    margin-left: 0;
  }
</style>
